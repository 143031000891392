.message-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #145DA0; /* Midnight Blue background */
}

.message-container {
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: background-color 0.3s, box-shadow 0.3s; /* Smooth transition on hover */
}

.message-container:hover {
    background-color: #145DA0; /* Midnight Blue background on hover */
    box-shadow: 0 0 10px rgba(20, 93, 160, 0.5); /* Subtle halo-like box-shadow on hover */
}


.message-text {
    font-size: 18px;
    color: #B1D4E0; /* Baby Blue text */
    margin: 5px 0;
}
