.about-page {
    text-align: center;
    padding: 30px;
}

.workflow-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px; /* Add spacing between steps */
}

.workflow-step {
    text-align: center;
    width: 250px; /* Adjust the width based on your design */
    padding: 20px;
    background-color: #f2f2f2; /* Add a background color for each step */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative; /* To position arrows relative to steps */
}

.workflow-step img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
}

.workflow-step p {
    font-size: 18px;
    margin-top: 10px;
}

.about-page h2 {
    margin-top: 100px;
    font-size: 30px; /* Increase the font size for a bolder appearance */
    color: #007bff; /* Choose a cool color */
    text-transform: uppercase; /* Uppercase the text for emphasis */
    letter-spacing: 2px; /* Add letter spacing for a modern look */
    margin-bottom: 20px;
}

.arrow-right {
    position: absolute;
    top: 50%;
    right: -20px; /* Adjust the position based on your design */
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 20px solid #f2f2f2; /* Same background color as workflow step */
}
