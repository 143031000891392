/* CSS for the cookie banner */
.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffc979;
    color: #fff;
    text-align: center;
    padding: 15px;
    z-index: 9999;
    display: flex; /* Set display to flex */
    justify-content: left;
    height: 10vh;
    overflow: auto;
}

.cookie-banner-content {
    display: flex; /* Set display to flex */
    align-items: center;
}

.cookie-banner p {
    margin: 0;
    padding-right: 10px;
    color: #4E4F50;
    font-size: 90%;
}

.left-section img{
    width:40%;
    height:40px;
}

.left-section p{
    font-size: 50%;
    font-style: italic;
}

.cookie-banner .accept-button {
    background-color: #4892ff;
    color: #E2DED0;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.cookie-banner .accept-button:hover {
    background-color: #ff8a7c;
}
